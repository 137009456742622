// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

select {
    width: 100%;
    padding: .6em 1.5em;
    padding-left:10px;
    font-size: 16px;
    font-style: italic;
    margin: 0;
    border-radius: 0;
    background: #ffffff;
    color: $light-gray;
    border:none;
    outline:none;
    display: inline-block;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    cursor:pointer;
}


/* Targetting Webkit browsers only. FF will show the dropdown arrow with so much padding. */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    select {padding-right:18px}
}

.halfwidth {
    width: 46.2%;
    clear: none !important;
    float: left;
}

.halfwidth:nth-child(odd) {
    margin-right: 5%;
}

.halfwidth input {
  width: 100% !important;
}

input, textarea {
  border: none !important;
  padding: 0.5em !important;
}

.expsearch-field {
      padding: 0 0 0 34px !important; 
}

label {
  font-weight: 700;
  color: $dark-gray;
  text-transform: uppercase;
  font-size: 17px;
  margin-bottom: 0 !important;
}

.gform_wrapper {
  margin: 0 !important;
}
