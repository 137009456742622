// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

.item {
	width: 32.5%;
	margin-bottom: 10px;
}

/* Small devices (tablets, 768px and up) */
@media (max-width: $screen-sm-min) {
.item {
	width: 100%;
	margin-bottom: 10px;
}
.nopadonmobile {
    padding-bottom: 0 !important;
}
}

@media (max-width: $screen-md-min) {
.item {
	width: 48.5%;
	margin-bottom: 10px;
}
}

@media (min-width: 768px) {
.sidebar {
    float: right;
    width: 25%;
}
}

.sub-menu {
    display: none;
}

.sub-menu a:focus, .sub-menu a:hover {
    color: $brand-primary;
    text-decoration: none;
}

.submenu .active a{
    font-weight: 700;
}

.menu-item-has-children:hover .sub-menu {
    display: block;
    list-style: none;
    padding: 15px;
    padding-left: 45px;
    background: #fff;
    margin-right: -300px;
    margin-left: -30px;
}

.navbar-default .menu-item-has-children:hover {
  height: 45px;
}

li.menu-item.menu-item-has-children a:first-child::after {
    font-family: FontAwesome;
    content: "\f107";
    margin-left: 5px;
}

li.menu-item.menu-item-has-children:hover a:first-child::after {
    content: "\f106";
}

.sub-menu li.menu-item a:first-child:after {
    display: none;
}

#primary_navigation .sub-menu a:before {
    font-family: FontAwesome;
    content: "\f105";
    margin-right: 5px;
}

#primary_navigation .sub-menu a {
    color: $dark-gray !important;
    font-weight: 700 !important;
    text-transform: initial;
}

.nav-tabs {
    border-bottom: 1px solid #e8e3dd;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    text-align: center;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: $brand-primary;
    background-color: #fff;
    border: 1px solid #e8e3dd;
    border-bottom-color: transparent;
    cursor: default;
    border-radius: 0;
    font-weight: 700;
}

#footer_navigation>li>a {
    padding-top: 0;
    padding-bottom: 0;
    text-transform: uppercase;
    font-size: 12px;
}

#footer_navigation>li:after {
    content: "";
    background: #9294a3;
    position: absolute;
    left: 0;
    top: 2px;
    height: 17px;
    width: 1px;
}

.navbar-collapse .navbar-right {
    margin-right: 0;
}

.menu-item-has-children:hover .sub-menu li {
  margin-bottom: 1em;
}
