.yhteis {
    display: table;
    width: 100%;
}

.yhteis [class*="col-"] {
    float: none;
    display: table-cell;
}

/* Small devices (tablets, 768px and up) */
@media (max-width: $screen-sm-min) {
  .yhteis {
      display: block;
      width: 100%;
  }

  .yhteis [class*="col-"] {
      float: left;
      display: block;
  }
}
