.blogitems a:hover {
	text-decoration: none !important;
}

.blogitems [class*='col-'] .cont {
border: 1px solid #d6d6d6;
}

.blogitems [class*='col-'] img{
width: 100%;
}

.blogitems [class*='col-'] .blogtext h4{
color: $dark-blue;
font-size: 21px;
text-transform: initial;
}

.blogitems [class*='col-'] .blogtext, .blogitems .multiple {
padding: 25px;
}

.blogitems [class*='col-'] ul{
list-style: none;
}

.blogitems [class*='col-'] li{
border-bottom: 1px solid #d6d6d6;
padding-top: 1em;
padding-bottom: 1em;
}

.blogitems [class*='col-'] li span, .blogtext span{
font-family: 'Open Sans', sans-serif;
color: #9294a3;
font-weight: 700;
font-size: 17px;
}

.blogtext span {
	display: block;
	margin-bottom: 1em;
}

.blogitems [class*='col-'] li {
	clear: both;
	overflow: hidden;
}

.blogitems [class*='col-'] li span, .blogitems [class*='col-'] li h5 {
	float: left;
	display: block;
}

.blogitems [class*='col-'] li h5 {
    margin-top: 0.4em;
}

h5.blogtitle a {
    color: $dark-blue;
    font-size: 16px;
    border-left: 3px solid $brand-primary;
    margin-left: 2em;
    padding-left: 1em;
}

h5.blogtitle a:hover {
	color: $brand-secondary;
	text-decoration: none;
}

.postnavigation a:first-child {
	float: right;
}

.postnavigation a:last-child {
	float: left;
}
