.widhead {
    display:table;
    width: 100%;
}

.admin-bar .navbar-fixed-top {
	top: 32px;
}

.headtexts {
    height:100%;
    display:table-cell;
    vertical-align:middle;
    text-align:center;
}

.headtexts h1 {
	color: $brand-primary;
  text-transform: uppercase;
}

.headtexts .btn-default {
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 30px;
	padding-right: 30px;
}

header .container-fluid {
	padding-left:0;
	padding-right:0;
}

header .container-fluid nav {
    padding-left: 15px;
    padding-right: 15px;
    border: none;
}

.navbar {
	margin-bottom: 0;
}

.navbar-brand {
    float: left;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 0;
    height: auto;
    line-height: initial;
}

.navbar-default {
    background-color: #fff;
    border-color: transparent;
	padding-top: 10px;
    padding-bottom: 5px;
    border-radius: 0;
    min-height: 65px;
    overflow: visible;
}
.navbar-secondary {
	min-height: initial;
}
#secondary_navigation {
    padding-left: 15px;
}
#secondary_navigation li {
	background: $dark-blue;
}
#secondary_navigation li:first-child {
    background: #3D83C2;
}
#secondary_navigation li:last-child {
	background: $dark-gray;
}
#secondary_navigation li a:after{
	margin-left: 0.5em;
    font-family: FontAwesome;
    content: "\f105";
}
.navbar-secondary .navbar-nav>li>a {
	padding-top: 5px;
	padding-bottom: 5px;
	color: #fff;
}

nav li a{
	font-family: "Open Sans";
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	font-size: 14px;
}

nav li a:hover, nav li.active a{
	color: $brand-secondary;
}

.expsearch-form label, .nav-secondary a  {
    color: $dark-gray;
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
	font-size: 14px;
	margin-right: 20px;
	font-weight: 400;
    margin: 0;
    margin-right: 1em;
}

.expsearch-form label {
	text-transform: normal;
	text-transform: capitalize;
	cursor: pointer;
}

.navbar-secondary {
	background: $light-gray;
	margin-bottom: 0;
	border-radius: 0;
	border: none;
}

.nav-primary .active a{
	color: $brand-secondary;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
	background: transparent;
}
.navbar-default .navbar-nav>li>a, .navbar-default .navbar-text {
	color: #5f606c;
}
.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-text:hover {
	color: $brand-secondary;
}

.navbar-default .navbar-nav>li:after {
    content: "";
    background: #5f606c;
    position: absolute;
    right: 0;
    top: 17px;
    height: 17px;
    width: 1px;
}

.home .navbar-default {
	background: transparent;
}

.headerscrolled {
	background: #fff !important;
}

@media (min-width: 480px) {
.container {
    margin-top: 0;
    margin-bottom: 0;
}
}

.firstcont {
    margin-top: 95px;
}

.home .firstcont {
	margin-top: 0;
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (max-width: $screen-sm-min) {
header .container {
    text-align: center;
}
.nav-secondary {
	display: none;
}
.home .wrap.container {
margin-top: auto;
}
}

@media (max-width: 1130px) {
.navbar-right .btn {
  display: None;
}

}

@media (max-width: $screen-lg-min) {

}
