@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700);

body {
	font-family: 'Source Sans Pro', sans-serif;
	color: #000000;
	font-size: 16px;
}

small {
    font-size: 14px;
}

.row {
    padding-top: 15px;
    padding-bottom: 15px;
}

.container .row:first-child {
    padding-top: 70px;
}

.container .row:last-child {
    padding-bottom: 70px;
}

footer .row {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.copyright {
    color: #9294a3;
    text-transform: uppercase;
}

.bgpickup {
    text-align: center;
}

.bgpickup h2 {
    color: #fff;
}

a {
    color: $brand-secondary;
    text-decoration: none;
    font-weight: 700;
}

a:hover {
    text-decoration: underline;
}

p {
    margin: 0 0 30px;
}

p blockquote {
    margin-bottom: 30px !important;
}

strong {
    color: $dark-gray !important;
}

footer strong {
    color: #cdc7c2 !important;
}

.nav>li>a:focus, .nav>li>a:hover {
    background-color: transparent;
}

#cff.cff-fixed-height {
    height: 375px !important;
    padding: 30px !important;
}

time {
    font-style: italic;
    font-size: 16px;
}

.alert {
    margin-top: 20px;
}

.thumbnail, .wp-caption {
	max-width: 100% !important;
}

img {
    max-width: 100%;
}

.breadcrumb {
	background-color: transparent;
    margin-bottom: 0;
    padding-left: 0 !important;
}

.breadcrumb>li, .breadcrumb>li a {
    display: inline-block;
    font-size: 14px;
    color: $light-gray;
    font-style: italic;
}

.breadcrumb>li+li:before {
    content: "> ";
    color: $light-gray;
}

p {
    line-height: 1.5;
}

h1,h2,h3,h4,h5,h6 {
	font-family: Fabrikat-Light, sans-serif;
	color: $dark-blue;
  margin-top: 0;
}

h1 {
	font-size: 62px;
  font-weight: 300;
}

h2 {
    font-size: 52px;
    font-weight: 300;
}

h3 {
    font-size: 40px;
    font-weight: 400;
}

h4 {
    font-size: 21px;
    font-family: 'Open Sans';
    font-weight: 700;
    text-transform: uppercase;
}

h5 {
	font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 700;
}

h6 {
    color: $darker-gray;
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 700;
}

.bluehead {
    font-size: 26px;
    color: #42456a;
    font-weight: 400;
    text-transform: uppercase;
    font-family: Fabrikat-Medium, sans-serif;
}

.entry-title {
    color: $brand-primary;
}

.blocklink {
	text-decoration: none !important;
	color: inherit !important;
	font-weight: inherit !important;
}

.imagerow {
	padding-top: 35px !important;
	padding-bottom: 35px !important;
}

.block-title {
    color: #fff;
    font-weight: 300;
    font-size: 26px;
		font-family: Fabrikat-Light, sans-serif;
}

.onecolumn {
    font-size: 22px;
    color: $dark-blue;
}

.panel-group .panel {
    border: none;
}

.panel-default>.panel-heading {
    color: #333;
    border-color: #ddd;
    background: #fff;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 0;
}

.panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body {
border-top: none;
}

.panel-heading .accordion-toggle:after {
    content: " ";
    display: block;
    background: url('../../assets/images/aopen.png');
    background-repeat: no-repeat;
    background-size: cover;
    float: right;
    color: #eae5df;
    border-radius: 500px;
    font-size: 40px;
    height: 43px;
    width: 43px;
    text-align: center;
    margin-top: -15px;
    color: $brand-secondary;
}
.panel-heading .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: " ";
    color: #eae5df;
    background: url('../../assets/images/aclose.png');
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    padding-left: 0;
}

.accordion i
{
    padding: 5px 10px;
    display: inline-block;
    border-radius: 500px;
    width: 50px;
    border: 1px solid #EAE5DF;
    box-shadow: 0 0 1px #EAE5DF;
    float: right;
    display: block;
    font-size: 40px;
    text-align: center;
    color: #EAE5DF;
    margin-top: -25px;
}

ul {
    font-size: 16px;
    padding-left: 0;
    list-style-position: inside;
}

.nav-tabs>li>a:hover {
    border-radius: 0;
}

.cff-author a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: $dark-gray;
    font-size: 14px;
}
.cff-post-text {
        text-align: left;
}
#cff .cff-author {
    margin-bottom: 0 !important;
}
.cff-text{

    font-size: 14px !important;
}

.accordion-toggle {
    color: $brand-secondary !important;
}

.widget {
    margin-top: 2em;
}

.widget h5 {
    padding-bottom: 0.5em;
    margin-bottom: 0;
}

.entry-summary p {
    font-size: 16px;
}

.widget li {
    color: #3d3d3d;
    list-style: none;
    border-bottom: 1px solid #d3d3d3;
    padding-top: 1em;
    padding-bottom: 1em;
}

.widget h5, .widget ul {
        padding-left: 1em;
    padding-right: 1em;
}

.widget li:last-child {
    border-bottom: none;
}

.nostoicons p {
    font-size: 16px !important;
}

.nostowrap {
    padding: 25px;
    padding-top: 60px;
}

.pageheader h1 {
    color: #fff;
		text-transform: uppercase;
}

.nostowrap:before {
    content: " ";
    background: $brand-primary;
    width: 30%;
    height: 5px;
    display: block;
    position: absolute;
    top: 30px;
}

#cff .cff-post-links a {
    font-size: 11px;
}

#cff .cff-date {
    font-size: 11px;
}

.apuicons {
    text-align: center;
}

.desaturate{
    filter: grayscale(100%); /* Current draft standard */
    -webkit-filter: grayscale(100%); /* New WebKit */
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%); /* Not yet supported in Gecko, Opera or IE */
    filter: gray; /* IE */
    -webkit-filter: grayscale(1); /* Old WebKit */
}

/* centered columns styles */
.row-centered {
    text-align:center;
}
.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
}
ul {
	list-style: none;
}
li:before {
	font-family: FontAwesome;
	content: '\f00c';
	margin-right: 5px;
	color: #313249;
}
header li:before, footer li:before, .multiple li:before  {
display: none;
}

@media (max-width: $screen-md-min) {
	body, ul, li {
		font-size: 14px;
	}
	small {
	    font-size: 12px;
	}
	.row {
	    padding-top: 5px;
	    padding-bottom: 5px;
	}
	h1 {
		font-size: 40px;
	}
	h2 {
		font-size: 35px;
	}
	h3 {
		font-size: 20px;
	}
	h4 {
		font-size: 16px;
	}
	h5 {
		font-size: 14px;
	}
	h6 {
		font-size: 16px;
	}
	.block-title {
	    font-size: 20px;
	}
	.topfooter .col-xs-12 {
		padding-left: 1em !important;
		height: auto !important;
	}
	.widhead {
		height: auto !important;
		min-height: 65vh !important;
	}
	.nobgonmobile {
		background: none !important;
	}
	.refrow .bluehead {
		padding-left: 1em;
	}
	.multiple {
		margin-top: 1em;
	}
}
@media (max-width: $screen-sm-min) {
	.firstcont {
	    margin-top: 65px !important;
	}
	.navbar-toggle {
		margin-top: 4px !important;
	}
.navbar-collapse.collapse, .navbar-collapse.collapsing {
		border-bottom: 1px solid #E7E9EF;
		padding-bottom: 2em;
		border-color: #E7E9EF;
		background: white;
}
.menu-item.menu-, .expsearch-form {
	display: none !important;
}
.sub-menu li {
	margin-bottom: 0.5em !important;
}
.sub-menu {
	padding: inherit !important;
	margin-left: auto !important;
	margin-right: auto !important;
	padding-left: 15px !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	display: block !important;
}
.hidemeonmobile {
	display: none;
}
}

.navbar-default .navbar-toggle {
	border: none;
}

.navbar-default .navbar-toggle .icon-bar {
	background-color: $dark-blue;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
background: transparent;
}
