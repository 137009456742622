// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #FB832D; //Orange
$brand-secondary:		#2070B8; //Blue

$light-gray:			#F1F2F6;
$darker-gray:			#D3D5DF;
$dark-gray:				#7E7F8F;
$darkest-gray:			#5F606C;

$dark-blue:				#41456A;
$yellow:				#F8C321;

