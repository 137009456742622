// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($line-height-computed / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($line-height-computed / 2);
  height: auto;
}
@media (min-width: $screen-sm-min) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($line-height-computed / 2);
  }
  .alignright {
    float: right;
    margin-left: ($line-height-computed / 2);
  }
}

// Captions
.wp-caption {
  @extend .thumbnail;
}
.wp-caption-text {
  padding: $thumbnail-caption-padding;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}

.expsearch-form {
  display: inline-block;
  float: left;
    padding-top: 7px;
    padding-left: 10px;
}

.expsearch-field {
  background-color: transparent;
  background-image: url('../../assets/images/search.png');
  background-repeat: no-repeat;
  background-size: 30px 30px;
  border: none;
  cursor: pointer;
  height: 30px;
  margin: 3px 0;
  padding: 0 0 0 34px;
  position: relative;
  -webkit-transition: width 400ms ease, background 400ms ease;
  transition:         width 400ms ease, background 400ms ease;
  width: 0;
}

.expsearch-field:focus {
    background-color: #fff;
    border: none;
    padding-left: 40px;
    cursor: text;
    outline: 0;
    width: 230px;
}

.expsearch-submit {
  display: none;
}

input[type="search"] {
  -webkit-appearance: textfield;
}


header .search-field {
  width: auto !important;
}

main .search-form .search-field {
  font-size: 16px !important;
}


header .search-form {
    display: inline-block;
    position: absolute;
    bottom: 10px;
    right: 0;
    width: auto;
}

.search .page-header {
  text-align: center;
  border-bottom: none;
}

.search main {
  width: 100% !important;
  text-align: center;
}

.search article {
  text-align: left;
}

.thumbnail, .wp-caption {
    border: none;
    font-style: italic;
}

.desci, .wp-caption-text {
    font-size: 14px;
    margin-top: 15px;
    padding-top: 0;
    padding-bottom: 0;
    border-left: 4px solid $brand-primary;
    color: #000;
}

.desci strong {
  font-style: normal;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.thumbnail, .wp-caption {
    font-style: normal;
    background: transparent;
}
