footer {
	font-family: 'Open Sans';
	font-weight: 700;
	background: $darkest-gray;
	color: $light-gray;
	font-size: 12px;
}

footer p {
	    margin-bottom: 0;
}

footer a {
	color: #fff;
}

.topfooter {
	background: $light-gray;
}

.topfooter h6 {
    color: #9294a3;
    margin-bottom: 30px;
}

.lastcolfoot a {
	margin-left: 0.75em;
	margin-right: 0.75em;
}