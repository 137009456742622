.btn-default, .search-form .search-submit, input[type=submit] {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
    color: #fff;
    background-color: $brand-primary;
    border-color: transparent;
    border-radius: 3px;
    border-width: 0;
    text-transform: uppercase;
    font-size: 15px;
	padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
		min-width: 200px;
}

.btn-default:hover, .btn-default:focus, input[type=submit]:hover {
    color: #fff;
    background-color: #D7702B;
}

.btn-secondary, .postnavigation a {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #fff;
    background-color: $brand-secondary;
    border-color: transparent;
    border-radius: 3px;
    border-width: 0;
    text-transform: uppercase;
    font-size: 15px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
}

.btn-secondary:hover, .btn-secondary:focus {
    color: #fff;
    background-color: #1D5F92;
}

.row .btn {
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn:after, input[type=submit]:after {
    margin-left: 5px;
    font-family: FontAwesome;
    content: "\f105";
}

.nobox {
    background: transparent;
    border: 1px solid white;
}
